<template>
  <button
    type="button"
    class="px-6 py-3 rounded outline-none bg-black text-white"
    :class="className"
    @click.prevent="$emit('click')"
    :disabled="loading"
  >
    <i class="fas fa-fw fa-spin fa-sync" v-if="loading"></i>
    <slot v-else></slot>
  </button>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: null,
      validator: function (value) {
        return ["xs", "sm", "md", "lg", "xl"].indexOf(value) !== -1;
      },
    },
    block: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    className: function () {
      let output = this.loading ? "cursor-wait" : "hover:bg-gray-800";

      if (this.size) {
        output += ` text-${this.size}`;
      }

      output += this.block ? ' w-full block' : ' ml-2 inline-block'

      return output;
    },
  },
};
</script>
